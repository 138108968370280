/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import '../../styles/popup.css';

const LinkedInPostPopup = () => {
  const [isVisible, setIsVisible] = useState(true); // Control popup visibility

  const closePopup = () => {
    setIsVisible(false); // Hide the popup when close button is clicked
  };

  if (!isVisible) return null; // Don't render the popup if it's not visible

  return (
    <div className="popup-overlay">
      <div className="linkedin-post">
        {/* Custom Close Button */}
        <div className="custom-close-icon" onClick={closePopup}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path fill="currentColor" d="M18.3 5.71a1 1 0 00-1.41 0L12 10.59l-4.89-4.88a1 1 0 00-1.41 1.41L10.59 12l-4.88 4.89a1 1 0 101.41 1.41L12 13.41l4.89 4.88a1 1 0 001.41-1.41L13.41 12l4.88-4.89a1 1 0 000-1.41z" />
          </svg>
        </div>

        {/* Post Header */}
        <div className="post-header">
          <img
            src="https://ajithjoseph.com/images/me.jpg"
            alt="Ajith Joseph"
            className="profile-pic"
          />
          <div className="user-info">
            <h3>Ajith Joseph (He/Him)</h3>
            <p>FullStack Software Engineer @ Photon | React, .NET Core | MCPD, MCTS</p>
          </div>
        </div>

        {/* Post Content */}
        <div className="post-content">
          🎉 Thrilled to share that I've been recognized as one of the top 100 scorers in the
          <a target="_blank" href="https://www.linkedin.com/posts/ajithjozef_watsonxai-ibmtechxchange-watsonxhackathon-activity-7256538925840637952-0lau" rel="noreferrer">IBM TechXchange Pre-Conference Watsonx Hackathon!</a> 🚀
          <br /><br />
          Huge thanks to <a target="_blank" href="https://www.linkedin.com/posts/ajithjozef_watsonxai-ibmtechxchange-watsonxhackathon-activity-7256538925840637952-0lau" rel="noreferrer">IBM</a> for this amazing opportunity to learn, build, & compete with incredible minds using the power of <a target="_blank" href="https://www.linkedin.com/posts/ajithjozef_watsonxai-ibmtechxchange-watsonxhackathon-activity-7256538925840637952-0lau" rel="noreferrer">#watsonxai</a>!
          <br /><br />
          Excited to see what the future holds!
          <br /><br />
          #IBMTechXchange #WatsonxHackathon #AI #DataScience #Top100 #Grateful #ExcitedForWhatsNext #IBM #WatsonxAI
        </div>

        {/* Post Image */}
        <div className="post-image">
          <img
            src="/images/ibm.jpg"
            alt="IBM TechXchange Certificate"
          />
        </div>

        {/* Post Footer */}
        <div className="post-footer">
          <p>6 comments • IBM and 15 others</p>
          <a href="https://www.linkedin.com/posts/ajithjozef_watsonxai-ibmtechxchange-watsonxhackathon-activity-7256538925840637952-0lau" target="_blank" rel="noopener noreferrer">View original post on LinkedIn</a>
        </div>
      </div>
    </div>
  );
};

export default LinkedInPostPopup;
